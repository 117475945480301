import { Editable, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StatusType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import { ignoreTags } from "App.constants";
import { Badge, Button } from "antd";
import API from "Api";
import { getCompletedSlate, isEqualEnglishWords, isEqualText, withCustomLogic } from "App.helpers";
import { AudioOutlined, BookOutlined, CheckOutlined, CommentOutlined, SoundFilled, SoundTwoTone } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import cx from "classnames";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import PanelButtonPlaceholder from "Components/PanelButtonPlaceholder";
import useDeepgram from "Hooks/useDeepgram";

const initialValue = [{ children: [{ text: "" }] }];

const ReadTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, tags, translate, alternatives, transcripts },
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setDictOpened,
  showComments,
  alignCenter = false,
  panelAbsolute,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  // const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const { data: count } = useQuery({
    queryKey: ["comments", "count", id],
    queryFn: () => API.comment.getCount({ id }),
    staleTime: Infinity,
  });

  // const { start, stop, transcript: textFromSpeech, words } = useAssemblyRecognizer({ tags, setStatus });
  const { start, stop, transcript: textFromSpeech, words } = useDeepgram({ tags, setStatus });

  // const words = useMemo(() => textFromSpeech.split(" "), [textFromSpeech]);

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
  }, [tags, editor, text]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  // audio transcript
  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  // const onComplete = useOnComplete({ tags, setStatus, id, notifyApi, editor, onTaskComplete, showSuccessMessage });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        wrong={status === "error" && props.leaf.audioIdx === (activeLeaf ?? 0) && !ignoreTags.includes(props.leaf.text)}
        underlined={status === "completed" || props.leaf.audioIdx <= (activeLeaf ?? -1) || props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  // const filteredRightTags = getCompletedSlate(tags).filter((el) => el.word && !SkipTags.includes(el.word));
  const cleanText = useMemo(() => text.replace(/[^a-zа-яё0-9]/gi, "").toLowerCase(), [text]);
  const cleanSpeechText = useMemo(() => textFromSpeech.replace(/[^a-zа-яё0-9]/gi, "").toLowerCase(), [textFromSpeech]);

  useEffect(() => {
    if (!cleanSpeechText) return;

    if (
      cleanSpeechText.includes(cleanText) ||
      isEqualText(cleanSpeechText, cleanText) ||
      alternatives.some((alt) => isEqualText(alt.text, cleanSpeechText))
    ) {
      stop();
      setStatus(StatusType.Completed);
      onTaskComplete(sentence.id);
    } else {
      // API.event.save({ sentence: { id }, lesson: { id: lesson.id }, text: textFromSpeech, task: activeType, type: "audio" });
      // const taggedAnswer = await API.lemma.get(textFromSpeech);
      const isCompleted = transcripts?.every((tr, idx: number) => {
        const isRight = isEqualEnglishWords(tr.text || tr.word, words[idx]);
        if (isRight) {
          setActiveLeaf((prev) => ((prev ?? 0) < idx ? idx : prev));
        }
        return isRight;
      });

      if (isCompleted) {
        stop();
        setStatus(StatusType.Completed);
        onTaskComplete(sentence.id);
      } else {
        // setStatus(StatusType.Error);
        // setTries((prev) => (prev ? prev + 1 : prev));
      }
    }
  }, [alternatives, transcripts, onTaskComplete, sentence.id, stop, cleanText, cleanSpeechText, words]);

  const onStartRecording = () => {
    setActiveLeaf(-1);
    setStatus(StatusType.Loading);
    start();
  };

  const onSkip = useCallback(() => {
    onTaskComplete(sentence.id).then(() => onNext());
  }, [onNext, onTaskComplete, sentence.id]);

  const translateRef = useRef<HTMLDivElement>(null);

  translateRef.current?.scrollIntoView();

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly
                  onKeyDown={() => setStatus(StatusType.Editing)}
                  renderLeaf={renderLeaf}
                  //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                />
              </Slate>
            </form>
          </div>
          <div ref={translateRef} className={styles.translate}>
            {translate}
          </div>
        </div>

        <div className={styles.bottom}>
          {status === "completed" ? <Button icon={<SoundFilled />} onClick={() => play?.() || audio?.play()} /> : <div />}
        </div>
      </div>

      <div className={cx(styles.panel, { [styles.panel_absolute]: panelAbsolute })}>
        <div className={styles.panel__content}>
          {status === "completed" ? (
            <>
              <Badge count={count} color={"blue"}>
                <Button onClick={() => showComments((prev) => !prev)} type={"link"} icon={<CommentOutlined style={{ fontSize: 26 }} />} />
              </Badge>
              <Button icon={<CheckOutlined />} className={styles.btn_next} type={"primary"} shape={"round"} onClick={() => onNext()}>
                далее
              </Button>
              <PanelButtonPlaceholder />
            </>
          ) : (
            <>
              <Button icon={<SoundTwoTone />} className={styles.btn_play} onClick={() => play?.() || audio?.play()} />
              <span className={styles.buttons}>
                <Button
                  onClick={() => ([StatusType.IsRecording, StatusType.Loading].includes(status) ? stop() : onStartRecording())}
                  className={styles.record}
                  type={"primary"}
                  icon={<AudioOutlined />}
                  shape="circle"
                  color={"red"}
                  data-recording={status === "isRecording"}
                  loading={status === "loading"}
                ></Button>
                <Button className={styles.btn_skip} size={"small"} type={"link"} onClick={onSkip}>
                  далее
                </Button>
              </span>

              <Button icon={<BookOutlined />} className={styles.btn_play} onClick={() => setDictOpened(true)} />
            </>
          )}
        </div>
      </div>

      {/*{contextHolder}*/}
    </div>
  );
};

export default ReadTask;
