import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomText, StatusType, TaskType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Badge, Button, notification } from "antd";
import API from "Api";
import { delay } from "lodash";
import cx from "classnames";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { CheckOutlined, CloseOutlined, CommentOutlined, SoundFilled } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import SentenceMaskedLeaf from "./SentenceLeaf";
import { useOnCheck } from "./Helpers/useOnCheck";
import { usePressEnter } from "./Helpers/usePressEnter";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useOnComplete } from "./Helpers/useOnComplete";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import SentenceTaskHelpButton from "./SentenceTaskHelpButton";
import styles from "./SentenceTask.module.scss";
import PanelButtonPlaceholder from "Components/PanelButtonPlaceholder";

const initialValue = [{ children: [{ text: "" }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const SentenceTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, tags, translate, alternatives },
  activeType,
  lesson,
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setDictOpened,
  showComments,
  showSuccessMessage = false,
  alignCenter = false,
  showGrammar,
  setTry,
  panelAbsolute,
  noTranslate,
  transcripts,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90 });

  const { data: count } = useQuery({
    queryKey: ["comments", "count", id],
    queryFn: () => API.comment.getCount({ id }),
    staleTime: Infinity,
  });

  const setInitialState = useCallback(
    (withFocus: boolean = true) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, initialValue, { select: withFocus });
      if (withFocus) {
        try {
          ReactEditor.focus(editor);
        } catch (e) {}
      }
    },
    [editor],
  );

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState(true);
  }, [sentence.id, editor, setInitialState]);

  // audio transcript
  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  const onComplete = useOnComplete({ text, tags, setStatus, id, notifyApi, editor, onTaskComplete, showSuccessMessage });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onCheck = useOnCheck({
    editor,
    activeType,
    text,
    tags,
    id,
    lesson,
    alternatives,
    notifyApi,
    onComplete,
    setStatus,
  });

  const onHintAnswer = async () => {
    const text = editor.children[0]?.children?.map((el: CustomText) => el.text).join("");
    API.event.save({ text, type: "hint", task: activeType, lesson: { id: lesson.id }, sentence });

    const status = await onCheck(text, true);
    const wrongIdx = editor.children[0]?.children.findIndex((child: CustomText) => child.status === "wrong");
    if (wrongIdx >= 0) {
      Transforms.insertText(editor, "", { at: [0, wrongIdx] });
      await onCheck("", true);
    }

    if (status) {
      const hasError = editor.children[0]?.children.every((el: CustomText, idx: number) => {
        if (["missed", "lemma", "typo"].includes(el.status || "")) {
          setTry(true);
          Transforms.setNodes(editor, { status: "success" }, { at: [0, idx] });
          Transforms.insertText(editor, el.word || el.answer || "", { at: [0, idx] });
          setActiveLeaf(el.idx || null);
          delay(() => setActiveLeaf(null), 2000);
          return false;
        }

        if (el.status === "order") {
          editor.children.forEach(() => {
            Transforms.delete(editor, { at: [0] });
          });

          editor.children = [];
          Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
          return false;
        }
        return true;
      });

      if (hasError) {
        setTry();
      }
    }
  };

  usePressEnter({ status, onNext, onCheck });

  const onReset = () => {
    setInitialState(status === StatusType.Editing);

    ReactEditor.focus(editor);
  };

  const translateRef = useRef<HTMLDivElement>(null);

  //  translateRef.current?.scrollIntoView();

  // console.log("sentenceTask", activeType, editor.children[0]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={status === "completed"}
                  onKeyDown={() => setStatus(StatusType.Editing)}
                  renderLeaf={renderLeaf}
                  //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                />
              </Slate>
            </form>
            {status !== "completed" && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
                onClick={onReset}
              />
            )}
          </div>
          {!noTranslate && (
            <div ref={translateRef} className={styles.translate}>
              {(status === StatusType.Completed || [TaskType.Translate, TaskType.Convert, TaskType.Masked].includes(activeType)) &&
                translate}
            </div>
          )}
        </div>

        <div className={styles.bottom}>
          <Button
            icon={<SoundFilled />}
            onClick={() => {
              play?.() || audio?.play();
              ReactEditor.focus(editor);
            }}
            style={{ visibility: status === "completed" || activeType === TaskType.Dictate ? "visible" : "hidden" }}
          />
        </div>
      </div>

      <div className={cx(styles.panel, { [styles.panel_absolute]: panelAbsolute })}>
        <div className={styles.panel__content}>
          {status === "completed" ? (
            <>
              <Badge count={count} color={"blue"}>
                <Button onClick={() => showComments((prev) => !prev)} type={"link"} icon={<CommentOutlined style={{ fontSize: 26 }} />} />
              </Badge>
              <Button icon={<CheckOutlined />} className={styles.btn_next} type={"primary"} shape={"round"} onClick={() => onNext()}>
                далее
              </Button>
              <PanelButtonPlaceholder />
            </>
          ) : (
            <>
              <PanelButtonPlaceholder />

              <Button type={"primary"} shape={"round"} onClick={() => onCheck()}>
                проверить
              </Button>
              <SentenceTaskHelpButton
                status={status}
                showComments={showComments}
                openDictionary={setDictOpened}
                onHintAnswer={onHintAnswer}
                audio={audio}
                play={play}
                showGrammar={showGrammar}
              />
            </>
          )}
        </div>
      </div>

      {contextHolder}
    </div>
  );
};

export default SentenceTask;
