import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomText, StatusType } from "App.types";
import { withHistory } from "slate-history";
import { createEditor, Editor, Transforms } from "slate";
import { Badge, Button, notification } from "antd";
import API from "Api";
import { withCustomLogic, isEqualText } from "App.helpers";
import { CheckOutlined, CloseOutlined, CommentOutlined, SoundFilled } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import { usePressEnter } from "./Helpers/usePressEnter";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useOnComplete } from "./Helpers/useOnComplete";
import cx from "classnames";
import SentenceTaskHelpButton from "./SentenceTaskHelpButton";
import PanelButtonPlaceholder from "Components/PanelButtonPlaceholder";
import { trim } from "lodash";

const initialValue = [{ children: [{ text: "" }] }];

const SentenceTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, tags, translate, alternatives },
  activeType,
  lesson,
  onTaskComplete,
  onNext,
  play,
  children,
  audio,
  setDictOpened,
  showComments,
  showSuccessMessage = true,
  alignCenter = false,
  setTry,
  showGrammar,
  panelAbsolute,
  noText,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);
  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom", bottom: 90, maxCount: 1 });

  const { data: count } = useQuery({
    queryKey: ["comments", "count", id],
    queryFn: () => API.comment.getCount({ id }),
    staleTime: Infinity,
  });

  const setInitialState = useCallback(
    (withFocus = true) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, [{ children: [{ text: "" }] }], { select: withFocus });
      if (withFocus)
        try {
          ReactEditor.focus(editor);
        } catch (e) {}
    },
    [editor],
  );

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    setInitialState();
  }, [sentence.id, editor, setInitialState]);

  const onComplete = useOnComplete({
    answer: translate,
    tags,
    setStatus,
    id,
    notifyApi,
    editor,
    onTaskComplete,
    showSuccessMessage,
  });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onCheck = useCallback(
    async (textAnswer: string = "") => {
      const userAnswerText = trim(textAnswer || editor.children[0]?.children?.map((el: CustomText) => el.text).join("")) || "";

      if (isEqualText(userAnswerText, translate) || alternatives.some((alt) => isEqualText(userAnswerText, alt.translate))) {
        return onComplete();
      }

      setTry();

      // const words = userAnswerText.split(" ");
      const rightWords = translate.split(" ");

      const spellResults = await API.speller.get([{ text: userAnswerText }]);

      API.event.save({ sentence, text: userAnswerText, type: "mistake", task: activeType, lesson: { id: lesson.id } });

      const withoutTypos = rightWords.every((rightWord) => {
        if (spellResults[0].some((result) => result.s.includes(rightWord))) {
          notifyApi.warning({ message: "Слово написано с ошибкой!" });
          return false;
        }
        return true;
      });

      if (withoutTypos) notifyApi.error({ message: "Неверное слово!" });
    },
    [notifyApi, editor.children, translate, alternatives, setTry, sentence, activeType, lesson.id, onComplete],
  );

  const onHintAnswer = () => {
    const text = editor.children[0]?.children?.map((el: CustomText) => el.text).join("");
    API.event.save({ text, type: "hint", task: activeType, lesson: { id: lesson.id }, sentence });

    setTry(true);
    if (editor.children.length) {
      Transforms.select(editor, {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      });
    }
    Transforms.removeNodes(editor);
    Transforms.insertNodes(editor, [{ children: [{ text: translate }] }]);
  };

  const onReset = () => {
    setInitialState(false);

    ReactEditor.focus(editor);
  };

  usePressEnter({ status, onNext, onCheck });

  const translateRef = useRef<HTMLDivElement>(null);

  translateRef.current?.scrollIntoView();

  return (
    <div className={styles.sentenceTask}>
      <div className={styles.content}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            {initialValue && !noText && (
              <form spellCheck="false">
                <Slate editor={editor} initialValue={initialValue}>
                  <Editable
                    className={styles.textArea}
                    readOnly={status === "completed" || ["listen", "read", "select"].includes(activeType)}
                    onKeyDown={() => setStatus(StatusType.Editing)}
                    renderLeaf={renderLeaf}
                    //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                  />
                </Slate>
              </form>
            )}
            {status !== "completed" && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 14 }} />}
                onClick={onReset}
              />
            )}
          </div>
          <div ref={translateRef} className={styles.translate}>
            {text}
          </div>
        </div>

        <div className={styles.bottom}>
          <Button
            icon={<SoundFilled />}
            onClick={() => (play ? play() : audio?.play())}
            style={{ visibility: status === "completed" ? "visible" : "hidden" }}
          />
        </div>
      </div>

      <div className={cx(styles.panel, { [styles.panel_absolute]: panelAbsolute })}>
        <div className={styles.panel__content}>
          {status === "completed" ? (
            <>
              <Badge count={count} color={"blue"}>
                <Button onClick={() => showComments((prev) => !prev)} type={"link"} icon={<CommentOutlined style={{ fontSize: 26 }} />} />
              </Badge>
              <Button icon={<CheckOutlined />} className={styles.btn_next} type={"primary"} shape={"round"} onClick={() => onNext()}>
                далее
              </Button>
              <PanelButtonPlaceholder />
            </>
          ) : (
            <>
              <Button icon={<SoundFilled />} onClick={() => (play ? play() : audio?.play())} />

              <Button type={"primary"} shape={"round"} onClick={() => onCheck()}>
                проверить
              </Button>
              <SentenceTaskHelpButton
                status={status}
                showComments={showComments}
                openDictionary={setDictOpened}
                onHintAnswer={onHintAnswer}
                audio={audio}
                play={play}
                showGrammar={showGrammar}
              />
            </>
          )}
        </div>
      </div>

      {contextHolder}
    </div>
  );
};

export default SentenceTask;
