import { useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { Breadcrumb, Button, Drawer, Flex, Image, Progress, Skeleton } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import type { FireworksHandlers } from "@fireworks-js/react";
import { Fireworks } from "@fireworks-js/react";
import API from "Api";
import { delay, round, sample, uniqBy } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { GERMAN_VOICES, STATIC_URL, STORY_TASKS, TaskTypes, VOICES } from "App.constants";
import SentenceComments from "Components/SentenceComments";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { MediaType, SentenceType, StatusType, TaskType } from "App.types";
import ButtonClose from "Components/ButtonClose";
import { TaskComponents } from "Components/SentenceTask";
import Dictionary from "Components/Dictionary";
import GrammarPanel from "Components/GrammarPanel";
import useProgress from "Hooks/useProgress";
import { getNonEnglishAudioUrl, getSentenceAudioUrl } from "App.helpers";
import CompleteWindow from "Components/CompleteWindow";
import StoryTask from "Components/SentenceTask/StoryTask";
import styles from "./Lesson.module.scss";
import cx from "classnames";
import { UserContext } from "App";
import { isMobile } from "react-device-detect";
import { CheckOutlined, HomeOutlined, VideoCameraTwoTone, ZoomInOutlined } from "@ant-design/icons";
import VideoBackground from "Components/SentenceTask/VideoBackground";
import VideoPlayer from "Components/SentenceTask/VideoPlayer";
import { useAllTasks } from "../useAllTasks";
import { useTranscripts } from "Hooks/useTranscripts";
import useFeedbackVideos from "./useFeedbackVideos";
import HintDrawer from "Components/HintDrawer";

import "./Lesson.css";

const Lesson = () => {
  const [lessonStatus, setLessonStatus] = useState<StatusType>();
  const [taskStatus, setTaskStatus] = useState<StatusType>();
  const [showComments, setShowComments] = useState(false);
  const [isDictOpened, setDictOpened] = useState(false);
  const [isGrammarOpened, setGrammarOpened] = useState(false);
  const [tries, setTries] = useState(1);
  const [audioIndex, setAudioIndex] = useState(0);
  const [videoDrawer, setVideoDrawer] = useState<boolean>(false);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [feedbackMedia, setFeedbackMedia] = useState<MediaType>();
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();
  const [infoDrawerStatus, setInfoDrawerStatus] = useState<"closed" | "active" | "">();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { id = "", course, sentId } = useParams();

  const { data: lesson } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id],
    queryFn: () => API.lesson.get(id),
  });

  const {
    story: { type, id: storyId },
  } = lesson || { story: {} };

  const { data: sentences } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["sentences", storyId],
    queryFn: () => API.sentence.getListByStoryId(storyId),
  });

  const onRepeat = useCallback(() => {
    API.progress.delete(id).then(() => {
      queryClient.invalidateQueries({ queryKey: ["progress", +id] });
    });

    setLessonStatus(undefined);
  }, [id, queryClient]);

  const {
    showProgressModal,
    progress,
    contextHolder,
    refetch: refetchProgress,
  } = useProgress({ lesson: sentId ? undefined : lesson, onRepeat });

  useEffect(() => {
    isDictOpened && setTries(0);
  }, [isDictOpened]);

  const allTasks = useAllTasks(sentences, state?.allTasks);

  const tasksToComplete = useMemo<any>(() => {
    const completedCards = progress?.filter((pr) => pr.sentence);

    return allTasks.filter(
      (c) => !completedCards?.some((completedCard) => completedCard.sentence.id === c?.id && completedCard.type === c.task),
    );
  }, [allTasks, progress]);

  useEffect(() => {
    if (sentId) {
      API.progress
        .delete(lesson.id)
        .then(() => {
          const order = sentences.find((s) => s.id === +sentId)?.order || -1;
          const completed = allTasks.filter((s: any, idx) => (state?.allTasks ? idx < +sentId : s?.lessonOrder < order));
          return Promise.all(
            completed.map((sent) =>
              API.progress.save({ lesson: { id: lesson.id }, tries: 1, type: sent?.task, sentence: { id: sent?.id ?? 0 } }),
            ),
          );
        })
        .then(() => navigate(`/course/${course}/lessons/exercise/${lesson.id}`, { replace: true }))
        .then(() => refetchProgress());
    }
  }, [allTasks, course, lesson.id, navigate, sentId, refetchProgress, sentences, state?.allTasks]);

  const activeSentence = useMemo<SentenceType | undefined>(() => tasksToComplete[0], [tasksToComplete]);

  const hasMovies = useMemo(() => activeSentence && activeSentence.medias.length > 1, [activeSentence]);

  useEffect(() => {
    if (showProgressModal) {
      setVideoDrawer(false);
    } else if (hasMovies && activeSentence && !sentId) {
      delay(() => setVideoDrawer(true), 1000);
    }
  }, [showProgressModal, hasMovies, activeSentence, sentId]);

  const percent = useMemo(() => {
    if (lessonStatus === StatusType.Completed) return 100;

    const tasksCount = allTasks.length;
    return round((100 / tasksCount) * (tasksCount - tasksToComplete.length), 2);
  }, [allTasks.length, lessonStatus, tasksToComplete.length]);

  const { mutateAsync, mutate } = useMutation({
    mutationFn: API.progress.save,
    retry: 3,
  });

  const user = useContext(UserContext);

  const transcripts = useTranscripts({ sentence: activeSentence, speechRate: lesson.speechRate, audioIndex });

  const audios = useMemo(() => {
    if (!activeSentence?.text || activeSentence.task === TaskType.Grammar) return;

    const { text, voice, speak } = activeSentence;
    const isCard = (activeSentence.isCard || type === "card") && !text.includes(" ");

    const rate = lesson.speechRate || "slow";

    let result: HTMLAudioElement[];

    if (!user?.isEnglish) {
      const audio1 = new Audio(getNonEnglishAudioUrl(text.trim(), GERMAN_VOICES[0], storyId, rate));
      const audio2 = new Audio(getNonEnglishAudioUrl(text.trim(), GERMAN_VOICES[1], storyId, rate));

      audio1.addEventListener("play", () => audio2.pause());
      audio2.addEventListener("play", () => audio1.pause());

      result = [audio1, audio2, audio1, audio2];
    } else {
      const audio1 = new Audio(getSentenceAudioUrl(activeSentence.id, speak || text.trim(), voice || VOICES[6].value, rate));
      const audio2 = new Audio(getSentenceAudioUrl(activeSentence.id, speak || text.trim(), voice || VOICES[7].value, rate));

      audio1.addEventListener("play", () => audio2.pause());
      audio2.addEventListener("play", () => audio1.pause());

      result = [audio1, audio2];
    }

    const setNextIdx = () => setAudioIndex((prev) => (prev === (isCard ? 3 : 1) ? 0 : prev + 1));

    result.forEach((audio) => {
      (audio as HTMLAudioElement).addEventListener("ended", setNextIdx);
      // (audio as HTMLAudioElement).addEventListener("pause", setNextIdx);
    });

    return result;
  }, [activeSentence, type, lesson.speechRate, user?.isEnglish, storyId]);

  const TaskComponent = activeSentence?.task && TaskComponents[activeSentence.task];

  const title = useMemo(
    () => activeSentence?.description?.trim() || activeSentence?.grammar?.title || TaskTypes[activeSentence?.task || ""]?.desc,
    [activeSentence?.description, activeSentence?.grammar?.title, activeSentence?.task],
  );

  const { getNextSuccess, getNextFailed } = useFeedbackVideos();

  const fireworkRef = useRef<FireworksHandlers>(null);

  const onTaskComplete = useCallback(() => {
    setAudioIndex(0);
    setTaskStatus(StatusType.Completed);

    setHintDrawerStatus("closed");
    if (activeSentence?.info) {
      delay(() => setInfoDrawerStatus("active"), 500);
    }

    if (tries === 1 && activeSentence?.markers.includes("fireworks")) {
      fireworkRef?.current?.start();
      delay(() => fireworkRef?.current?.waitStop(), 3000);
    }
    setTries(1);

    const result = { sentence: { id: activeSentence?.id || 0 }, lesson: { id: +id }, type: activeSentence?.task, tries };
    return mutateAsync(result);
  }, [activeSentence, id, mutateAsync, tries]);

  const onNextTask = useCallback(() => {
    fireworkRef?.current?.waitStop();
    setHintDrawerStatus("");
    setInfoDrawerStatus("");
    setTaskStatus(StatusType.Empty);
    setFeedbackMedia(undefined);

    const showSuccessFeedback = activeSentence?.markers.includes("congrats");
    if (showSuccessFeedback) {
      const media = getNextSuccess();
      setFeedbackMedia(media);
      setVideoDrawer(true);
    }

    const result = { sentence: { id: activeSentence?.id || 0 }, lesson: { id: +id }, type: activeSentence?.task, tries };
    queryClient.setQueryData(["progress", lesson.id], (data: []) => [...data, result]);

    if (tasksToComplete.length > 1) {
      mutate({
        lesson: { id: +id },
        percent,
        status: "progress",
      });
    } else {
      const tasksWithErrors = uniqBy(progress?.filter((pr) => pr.tries !== 1), (pr) => `${pr.sentence.id}${pr.type}`).length;
      const stars = ((allTasks.length - tasksWithErrors) / allTasks.length) * 5;

      mutate({
        lesson: { id: +id },
        percent: 100,
        stars,
        status: "completed",
      });

      setLessonStatus(StatusType.Completed);
    }
  }, [
    activeSentence?.markers,
    activeSentence?.id,
    activeSentence?.task,
    id,
    tries,
    queryClient,
    lesson.id,
    tasksToComplete.length,
    getNextSuccess,
    mutate,
    percent,
    progress,
    allTasks.length,
  ]);

  const completeLesson = useCallback(() => {
    mutate({
      lesson: { id: +id },
      percent: 100,
      status: "completed",
    });

    setLessonStatus(StatusType.Completed);
  }, [id, mutate]);

  const setTry = useCallback(
    (isHint: boolean) => {
      if (isHint) {
        setTries(0);
      } else {
        const showFailFeedback = activeSentence?.markers.includes("idiot");
        if (showFailFeedback) {
          const media = getNextFailed();
          setFeedbackMedia(media);
          setVideoDrawer(true);
        }
        setTries((prev) => (prev ? prev + 1 : prev));
      }
    },
    [activeSentence?.markers, getNextFailed],
  );

  useEffect(() => {
    if (lessonStatus === StatusType.Completed) {
      fireworkRef?.current?.start();
      delay(() => fireworkRef?.current?.waitStop(), sample([3000, 4000, 5000]));
    }
  }, [lessonStatus]);

  useEffect(() => {
    let delayId = 0;
    if (activeSentence?.markers.includes("showGrammar") && !showProgressModal && !videoDrawer) {
      setGrammarOpened(true);
    } else {
      setGrammarOpened(false);
    }
    if (activeSentence?.hint && !showProgressModal && !videoDrawer) {
      delayId = delay(() => setHintDrawerStatus((prev) => (prev === "closed" ? prev : "active")), 1000);
    } else {
      clearTimeout(delayId);
      setHintDrawerStatus((prev) => (prev === "closed" ? prev : ""));
    }
    return () => (delayId ? clearTimeout(delayId) : undefined);
  }, [activeSentence?.hint, activeSentence?.info, activeSentence?.markers, showProgressModal, videoDrawer]);

  console.log({ allTasks, tasksToComplete, activeSentence });

  return (
    <div className={cx(styles.lesson, { [styles.lesson__isMobile]: isMobile })}>
      {!isMobile && (
        <Breadcrumb
          items={[
            { title: <Button type={"link"} size={"small"} icon={<HomeOutlined />} onClick={() => navigate("/")}></Button> },
            { title: <Link to={`/course/${course}`}>{course}</Link> },
            { title: lesson.story.title },
          ]}
        />
      )}
      <div className={styles.header}>
        <Flex>
          <Progress percent={round(percent)} showInfo={false} />
          <ButtonClose path={course ? `/course/${course}` : "/"} lessonId={lesson.id} />
        </Flex>
      </div>

      <Skeleton active={!!sentId} loading={!!sentId}>
        <SwitchTransition>
          <CSSTransition key={`${activeSentence?.id}${activeSentence?.task}${progress?.length}`} timeout={500} classNames={"fade"}>
            <div>
              {title && activeSentence?.task !== TaskType.Intro && (
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
              )}

              <div
                className={cx(styles.content, {
                  [styles.content__fullHeight]:
                    lesson.story.type === "dialog" || activeSentence?.task === "grammar" || activeSentence?.linkedStoryId,
                })}
              >
                {lessonStatus === "completed" ? (
                  <CompleteWindow sentences={sentences} onRepeat={onRepeat} lessonId={id} course={course} />
                ) : (
                  activeSentence &&
                  ((activeSentence?.task && STORY_TASKS.includes(activeSentence.task)) ||
                  lesson.story.type === "dialog" ||
                  (activeSentence.linkedStoryId && activeSentence.task === TaskType.Video) ? (
                    <StoryTask
                      activeType={activeSentence.task}
                      story={activeSentence.linkedStory || lesson.story}
                      lesson={lesson}
                      onTaskComplete={onTaskComplete}
                      onNext={onNextTask}
                      media={activeSentence.media}
                      markers={activeSentence.markers}
                      autoPlay={!sentId && !showProgressModal}
                    />
                  ) : (
                    <TaskComponent
                      audio={audios?.[audioIndex]}
                      sentence={activeSentence}
                      transcripts={transcripts}
                      onNext={onNextTask}
                      activeType={activeSentence?.task}
                      onTaskComplete={onTaskComplete}
                      lesson={lesson}
                      setDictOpened={setDictOpened}
                      showComments={setShowComments}
                      setTry={setTry}
                      showGrammar={(activeSentence?.grammar || lesson.grammar) && setGrammarOpened}
                      // alignCenter={type === "card" || activeSentence?.isCard}
                      alignCenter={true}
                      showAnswer={activeSentence?.task === TaskType.SpellerListen}
                      sentences={sentences}
                      showSuccessMessage
                      autoPlay={!sentId && !showProgressModal && (!activeSentence.medias.length || isVideoPlayed)}
                      noTranslate={activeSentence?.markers.includes("noTranslate")}
                      noText={activeSentence?.markers.includes("noText")}
                      completeLesson={completeLesson}
                    >
                      {hasMovies ? (
                        <Button onClick={() => setVideoDrawer(true)} icon={<VideoCameraTwoTone style={{ fontSize: 48 }} />} type={"link"} />
                      ) : activeSentence?.medias.length || activeSentence.media?.includes(".mp4") ? (
                        <VideoPlayer
                          largeCaptionSize
                          showCaptions={
                            activeSentence.markers.includes("subtitles") ||
                            (activeSentence.markers.includes("subtitlesAfter") && taskStatus === StatusType.Completed)
                          }
                          medias={activeSentence.medias.length ? activeSentence.medias : [{ url: activeSentence.media }]}
                          autoPlay={!sentId && activeSentence.task !== TaskType.Listen}
                          onFinish={() => setIsVideoPlayed(true)}
                        />
                      ) : activeSentence?.media?.includes(".mp3") ? (
                        <audio controls src={`${STATIC_URL}/media/${activeSentence.media}`}></audio>
                      ) : (
                        activeSentence.media && (
                          <Image
                            preview={{
                              toolbarRender: () => null,
                              mask: <ZoomInOutlined style={{ fontSize: 24 }} />,
                              maskClassName: styles.mask,
                              title: "click to scale",
                            }}
                            onError={(e: any) => (e.target.style.visibility = "hidden")}
                            src={`${STATIC_URL}/media/${activeSentence?.media}`}
                            // style={{ backgroundImage: `url(${STATIC_URL}/media/${activeSentence.media})` }}
                            alt={""}
                            height={"100%"}
                          />
                        )
                      )}
                    </TaskComponent>
                  ))
                )}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Skeleton>
      {contextHolder}
      {!activeSentence?.linkedStoryId && (
        <Dictionary
          isOpen={isDictOpened}
          storyId={lesson.story.id}
          sentences={
            type === "card" ||
            (activeSentence?.task &&
              [TaskType.AudioSelect, TaskType.SpellerSelect, TaskType.Select, TaskType.ReverseSelect].includes(activeSentence.task))
              ? sentences
              : activeSentence
          }
          toggle={setDictOpened}
        />
      )}
      <HintDrawer open={hintDrawerStatus === "active"} text={activeSentence?.hint} onClose={() => setHintDrawerStatus("closed")} />
      <HintDrawer text={activeSentence?.info} open={infoDrawerStatus === "active"}>
        <Button
          size={isMobile ? "middle" : undefined}
          onClick={onNextTask}
          icon={<CheckOutlined />}
          className={styles.btn_next}
          type={"primary"}
          shape={"round"}
        >
          далее
        </Button>
      </HintDrawer>

      <Drawer
        zIndex={9999}
        size={"large"}
        height={isMobile ? 280 : undefined}
        styles={{ header: { display: "none" }, body: { padding: 0 } }}
        placement={"bottom"}
        open={activeSentence?.task !== TaskType.Video && (hasMovies || feedbackMedia) && videoDrawer}
        onClose={() => setVideoDrawer(false)}
      >
        {(activeSentence || feedbackMedia) && (
          <VideoBackground
            open={videoDrawer}
            onFinish={() => {
              setVideoDrawer(false);
              setIsVideoPlayed(true);
            }}
            medias={activeSentence?.medias}
            media={feedbackMedia}
            showCaptions={activeSentence?.markers.includes("subtitles")}
          />
        )}
      </Drawer>
      <SentenceComments isOpen={showComments} toggle={setShowComments} sentence={activeSentence} />
      <GrammarPanel isOpen={isGrammarOpened} grammar={activeSentence?.grammar || lesson?.grammar} toggle={setGrammarOpened} />
      <Fireworks
        ref={fireworkRef}
        autostart={false}
        options={{ acceleration: 1 }}
        style={{ position: "fixed", height: "100%", width: "100%", left: 0, top: 0, pointerEvents: "none", zIndex: "9" }}
      />
    </div>
  );
};

export default Lesson;
